<template>
  <v-container>
    <h1>Users</h1>
        <v-text-field prepend-icon="mdi-magnify" v-model="searchUsers" type="text" name="Search" id="user" placeholder="Search" />

    <div class="user-grid">
            <div v-for="user in filteredUsers" :key="user._id">
        <h3>
          <strong>Email:</strong>
          {{ user.email }}
        </h3>
        <p>
          <strong>Role:</strong>
          {{user.role}}
        </p>
        <router-link :to="{ path: 'users/'+ user._id}">See More</router-link>
      </div>
    </div>
  </v-container>
</template>

<script>
import { baseURL } from "@/components/http-common";
export default {
  data() {
    return {
      baseURL: baseURL,
      searchUsers: ''
    };
  },
  computed: {
    filteredUsers() {
      const search = this.searchUsers.toLowerCase().trim();

      if (!search) return this.users;

      return this.users.filter(
        user => user.email.toLowerCase().indexOf(search) > -1 || user.role.toLowerCase().indexOf(search) > -1
      );
    },
    users() {
      return this.$store.state.userStore.users;
    }
  },
  methods: {
    getUsers() {
      this.$store.dispatch("loadUsers");
    }
  },
  mounted() {
    this.getUsers();
  }
};
</script>

<style scoped>
.user-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.user-grid > div {
  border: 1px solid #e7e7e7;
  padding: 10px;
}
</style>